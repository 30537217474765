





































import {PageContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/PageContext';
import {VuDropdownItem, VuDropdownTrigger} from '@labor-digital/vuetiful/src/index';
import {AppEventKeys} from '../../../AppEventKeys';
import Dropdown from '../../Misc/Dropdown/Dropdown.vue';
import Icon from '../../Misc/Icon/Icon.vue';
import LinkWrap from '../../Misc/Link/LinkWrap/LinkWrap.vue';

export default {
    name: 'LoginMenu',
    components: {LinkWrap, Icon, Dropdown, VuDropdownItem, VuDropdownTrigger},
    props: {
        context: null as PageContext,
        metaMenu: {
            type: Boolean,
            default: false
        }
    },
    data()
    {
        return {open: false};
    },
    computed: {
        entries()
        {
            return (this.context as PageContext).commonElements.loginMenu;
        },
        classes()
        {
            return {
                'loginMenu--mobile': !this.metaMenu,
                'loginMenu--metaMenu': this.metaMenu
            };
        }
    },
    methods: {
        onOpenMenu()
        {
            this.open = true;
        }
    },
    mounted()
    {
        this.context.eventEmitter.bind(AppEventKeys.OPEN_LOGIN_MENU, this.onOpenMenu);
    },
    beforeDestroy()
    {
        this.context.eventEmitter.unbind(AppEventKeys.OPEN_LOGIN_MENU, this.onOpenMenu);
    }
};
