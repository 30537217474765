var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    {
      staticClass: "main fixedHeader",
      class: _vm.classes,
      attrs: { "data-stop-body-scrolling-fixed": "half" },
      on: { click: _vm.onClickDetected },
    },
    [
      _vm.getHeaderType === "show" || _vm.getHeaderType === "logo"
        ? _c("div", { staticClass: "upper" }, [
            _c(
              "div",
              { staticClass: "container" },
              [
                _c("logo"),
                _vm._v(" "),
                !_vm.isMobile && _vm.isMainLanguage && !_vm.isLandingpage
                  ? _c("master-toggle", { attrs: { context: _vm.context } })
                  : _vm._e(),
                _vm._v(" "),
                _vm.getHeaderType === "show"
                  ? _c(
                      "div",
                      { staticClass: "right" },
                      [
                        !_vm.isMobile && !_vm.isLandingpage
                          ? _c("meta-menu", {
                              attrs: {
                                context: _vm.context,
                                options: _vm.metaNavigation,
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.isLandingpage
                          ? _c(
                              "div",
                              { staticClass: "right__lower" },
                              [
                                !_vm.isMobile
                                  ? _c("LanguageSwitch", {
                                      attrs: { context: _vm.context },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.isMainLanguage
                                  ? _c(
                                      "a",
                                      {
                                        staticClass: "search",
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.toggleSearch()
                                          },
                                        },
                                      },
                                      [
                                        _c("icon", {
                                          staticClass:
                                            "fixedHeader__icon fixedHeader__icon--search",
                                          attrs: { icon: "search" },
                                        }),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("navigation.search.text")
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.isMainLanguage
                                  ? _c("LoginMenu", {
                                      staticClass:
                                        "fixedHeader__el fixedHeader__el--mobile",
                                      attrs: { context: _vm.context },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.isMobile && _vm.getHeaderType === "show"
                          ? _c(
                              "a",
                              {
                                staticClass: "burger",
                                on: {
                                  click: function ($event) {
                                    return _vm.toggleSidebar(
                                      _vm.isSidebarOpen ? null : "right"
                                    )
                                  },
                                },
                              },
                              [
                                _c("icon", {
                                  staticClass:
                                    "fixedHeader__icon fixedHeader__icon--burger",
                                  attrs: {
                                    icon: _vm.isSidebarOpen
                                      ? "close"
                                      : "burger",
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        _vm.isSidebarOpen
                                          ? "navigation.burger.close.text"
                                          : "navigation.burger.open.text"
                                      )
                                    )
                                  ),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "separator" }),
      _vm._v(" "),
      _vm.getHeaderType === "show"
        ? _c(
            "div",
            { staticClass: "lower" },
            [
              !_vm.isMobile
                ? _c("main-menu-desktop", {
                    attrs: { context: _vm.context },
                    on: {
                      dropdownOpen: function ($event) {
                        _vm.dropdownIsOpen = true
                      },
                      dropdownClose: function ($event) {
                        _vm.dropdownIsOpen = false
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }