var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Dropdown",
    {
      staticClass: "loginMenu",
      class: _vm.classes,
      attrs: { "with-arrow": "" },
      scopedSlots: _vm._u([
        {
          key: "trigger",
          fn: function () {
            return [
              _c(
                "VuDropdownTrigger",
                { staticClass: "loginMenu__button" },
                [
                  _c("Icon", {
                    staticClass: "loginMenu__buttonIcon",
                    attrs: { icon: "maleCircle" },
                  }),
                  _vm._v(" "),
                  _vm.metaMenu
                    ? _c("span", [
                        _vm._v(_vm._s(_vm.$t("navigation.login.text"))),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.open,
        callback: function ($$v) {
          _vm.open = $$v
        },
        expression: "open",
      },
    },
    [
      _vm._v(" "),
      _vm._l(_vm.entries, function (entry) {
        return _c(
          "VuDropdownItem",
          {
            key: entry.id,
            staticClass: "loginMenu__link",
            attrs: {
              link: entry.href,
              "link-component-props": { type: "external" },
            },
          },
          [_vm._v("\n        " + _vm._s(entry.title) + "\n    ")]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }