






















import {VuDropdownAbstract} from '@labor-digital/vuetiful/src/index';

export default {
    name: 'Dropdown',
    extends: VuDropdownAbstract
};
