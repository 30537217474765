









































































import {PlainObject} from '@labor-digital/helferlein/lib/Interfaces/PlainObject';
import {AppContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/AppContext';
import {PageContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/PageContext';
import {RootLineElementInterface} from '@labor-digital/typo3-vue-framework/lib/Core/Interface/RootLineElementInterface';
import {AppEventKeys} from '../../../AppEventKeys';
import {AppStoreKeys} from '../../../AppStoreKeys';
import LanguageSwitchMixin from '../../../Mixin/LanguageSwitchMixin';
import {MenuService} from '../../../Service/MenuService';
import Icon from '../../Misc/Icon/Icon.vue';
import HomeLinkWrap from '../../Misc/Link/HomeLinkWrap/HomeLinkWrap.vue';
import LinkWrap from '../../Misc/Link/LinkWrap/LinkWrap.vue';
import LoginMenu from '../LoginMenu/LoginMenu.vue';
import Logo from './Components/Logo/Logo.vue';

export default {
    name: 'FixedHeader',
    mixins: [LanguageSwitchMixin],
    components: {
        LoginMenu,
        LanguageSwitch: () => import('../LanguageSwitch/LanguageSwitch.vue'),
        Logo,
        MainMenuDesktop: () => import('../MainMenu/Desktop/MainMenuDesktop.vue'),
        HomeLinkWrap,
        MasterToggle: () => import('./Components/MasterToggle/MasterToggle.vue'),
        LinkWrap,
        MetaMenu: () => import('./Components/MetaMenu/MetaMenu.vue'),
        Icon
    },
    props: {
        context: null as PageContext,
        rootLine: null as Array<RootLineElementInterface>
    },
    data()
    {
        return {
            isSidebarOpen: false,
            dropdownIsOpen: false
        };
    },
    computed: {
        isMobile(): boolean
        {
            return this.context.store.get(AppStoreKeys.BREAKPOINT).is('<=', 'md');
        },
        isLandingpage(): Boolean
        {
            return MenuService.isHomepageForLandingpages() || MenuService.isLandingpage;
        },
        getHeaderType(): 'show' | 'hide' | 'logo'
        {
            return MenuService.isLandingHeaderType;
        },
        metaNavigation(): Array<PlainObject>
        {
            return MenuService.getMetaMenu();
        },
        classes()
        {
            return {
                'main--open': this.dropdownIsOpen
            };
        }
    },
    methods: {
        toggleSidebar(toggle)
        {
            this.isSidebarOpen = !!toggle;
            if (!!toggle) {
                this.context.store.set(AppStoreKeys.SIDEBAR_MAIN_MENU_FIXED, true);
            }
            this.context.store.set(AppStoreKeys.SIDEBAR_DIRECTION, !!toggle ? 'right' : null);
        },
        toggleSearch()
        {
            this.context.store.set(
                AppStoreKeys.SEARCH_OVERLAY_IS_VISIBLE,
                !this.context.store.get(AppStoreKeys.SEARCH_OVERLAY_IS_VISIBLE)
            );
        },
        onClickDetected()
        {
            (this.context as AppContext).eventEmitter.emit(AppEventKeys.CLOSE_MAIN_MENU_DROPDOWN);
        }
    },
    mounted(): void
    {
        this.$watch(() => this.context.store.get(AppStoreKeys.SIDEBAR_DIRECTION), (n) => {
            if (n === null) {
                this.isSidebarOpen = false;
            }
        });
    }
};
